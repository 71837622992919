$spotifyGreen: #1ED760;
.popup {
    position: fixed;
    z-index: 4;
    width: 500px;
    height: 200px;
    top:50%;
    left:50%;
    margin-top: -100px;
    margin-left: -250px; 
  }
  
.popup_content {
    background-color: #252525; 
    padding: 10px;
    margin:auto;
    width: 500px;
    border-radius: 20px;
    border: 1px solid $spotifyGreen;
    box-shadow: 0px 0px 15px $spotifyGreen;
    padding-bottom: 15px;
    font-size: 36px;
    overflow:auto;
}



.playlist_form {
    display: inline-block;
    padding-top: 35px;
    h3 {
        text-align: left;
        align-items: center;
        padding-bottom: 10px;
    }
}

.close {
    color: white;
    float: right;
}

.close:hover {
    color: $spotifyGreen;
    cursor: pointer;
}


.spotify-button {
    background: darken($spotifyGreen, 8%); 
    color: white;
    border: 1px solid darken($spotifyGreen, 8%);
    transition: all .5s;
    &:hover {
        background: $spotifyGreen;
        border: 1px solid $spotifyGreen;
        color: white
    }
}

.spotify-cancel-button {
    background: black; 
    color: gray;
    border: 1px solid gray;
    transition: all .5s;
    &:hover {
        border: 1px solid white;
        color: white;
    }
}

.public_toggle {
    padding-top: 10px;
}
