$spotifyGreen: #1ED760;

button {
    border-radius: 20px;
    border: none;
    padding: 5px 20px;
    letter-spacing: 1px;
    font-size: 28px;
    outline: none;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
}

.spotify-button {
    background: darken($spotifyGreen, 8%); 
    color: white;
    border: 1px solid darken($spotifyGreen, 8%);
    transition: all .5s;
    &:hover {
        background: $spotifyGreen;
        border: 1px solid $spotifyGreen;
        color: white
    }
}

.spotify-button:hover ~ .Logo {
    opacity: 0;
}

.App {
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-logo {
    height: 15vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 30s linear;
    }
  }
  
  .App-header {
    background-color: #1a1d24;
    min-height: 100vh;
    display: block;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-top: 10%;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  